import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const CONTAINER_V2_TYPES: IViewType[] = [
  'two-column-banner-lg',
  'fullwidth-promo-banner',
  'fullwidth-promo-banner-2',
  'fullwidth-promo-banner-3',
  'fullwidth-promo-banner-4',
  'landscape-promo-banner',
  'landscape-promo-banner-2',
  'landscape-promo-banner-3',
  'squat-banner',
  'top-page-promo',
  'top-page-promo-2',
  'top-page-promo-3',
  'four-products-carousel',
  'wall-of-brands',
]

export const CONTAINER_V2_TYPES_DEFAULT_VIEW: IViewType[] = [
  'fullwidth-banner-collection-promo',
  'fullwidth-banner-collection-promo-multiple',
  'landscape-banner-collection-promo',
  'landscape-banner-collection-promo-multiple',
  'top-page-banner-collection-promo',
  'top-page-banner-collection-promo-multiple',
  'three-item-board-carousel',
  'board-with-6-icons',
  'two-item-board-carousel',
  'board-with-4-icons',
  'instagram-carousel',
  'plain-slider',
]

export const DEFAULT_ACTIVE_PLACEMENTS: IViewType[] = [
  'full-width-banner',
  'two-column-banner-lg',
  'fullwidth-promo-banner',
  'fullwidth-promo-banner-2',
  'fullwidth-promo-banner-3',
  'fullwidth-promo-banner-4',
  'fullwidth-banner-collection-promo',
  'fullwidth-banner-collection-promo-multiple',
  'landscape-banner',
  'landscape-promo-banner',
  'landscape-promo-banner-2',
  'landscape-promo-banner-3',
  'landscape-banner-collection-promo',
  'landscape-banner-collection-promo-multiple',
  'top-page-banner',
  'top-page-promo',
  'top-page-promo-2',
  'top-page-promo-3',
  'top-page-banner-collection-promo',
  'top-page-banner-collection-promo-multiple',
  'boards-with-fields-below',
  'text-module',
  'squat-banner',
  'box-with-margin',
  'grid-of-boards-two-columns',
  'grid-of-boards-three-columns',
  'grid-of-boards-four-columns',
  'faqs',
  'faqs-with-link',
  'two-item-board-carousel',
  'three-item-board-carousel',
  'mod-box-with-margin',
  'mod-box-without-margin',
  'board-with-icons',
  'board-with-6-icons',
  'board-with-4-icons',
  'instagram-carousel',
  'grid-of-products',
  'combo-mini-slider-plus-box-all-fields',
  'cly-products',
  'query-list',
  'wall-of-brands',
  'cly-products-category-tabs',
  'cly-four-products-category-tabs',
  'plain-slider',
  'square-boards-with-split',
  'square-boards-without-split',
  'box-without-margin',
  'boards-with-fields-below-stack',
  'star-table',
  'index-table',
  'simple-table',
  'complex-table',
  'value-proposition-banner',
  'you-may-also-like',
  'newsletter-subscribe-banner',
  'promo-strip',
  'Algolia Recommendations',
  'anchor-buttons',
  'carousel-banner-with-products',
  'four-products-carousel',
  'box-and-2-products',
  'box-and-4-products',
  'recently-viewed',
  'size-guide',
  'mention-me',
]
