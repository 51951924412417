import React, { useContext } from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
// components
import { CollectionCta } from './components/CollectionCta'
import { CollectionText } from './components/CollectionText'
import {
  ContextWrapperData,
  PlacementContext,
} from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { getExpectedProductCount } from '@components/Cms/CmsComponents-CSS/BoxAndProducts'
import { CMS_MODULES as CM, DEFAULT_VIEW_TYPE } from '@components/Cms/constants'
// types
import { PlacementContainerProps } from './types'
import { PlacementContextType } from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/types/PlacementContextWrapper.types'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
// utils
import { showCollectionCta, showCollectionText } from './utils'
import { getPlacementBackgroundColor, isTeaserBackgroundColor } from '@utils/cms/background'
import { getTeaserItem } from '@utils/cms/teaser'
import { getItemsWithProductData, isBanner } from '@utils/placements'
import styles from './styles/PlacementContainer.module.scss'
import { generateAnchorID } from '@utils/page'

export const PlacementContainer: React.FC<PlacementContainerProps> = ({ modules }) => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const { placement, teaserIndex } = data ?? {}
  const {
    marginLateral = false,
    marginVertical = 'X',
    name: placementName = '',
    placementReflect = false,
    viewtype = DEFAULT_VIEW_TYPE,
  } = placement ?? {}
  const teaser = getTeaserItem(placement)

  const hasCollectionText = showCollectionText(teaser, viewtype)
  const hasCollectionCta = showCollectionCta(teaser, viewtype)

  const backgroundColor = getPlacementBackgroundColor({ placement, teaser })
  const flexDirection: string = viewtype === CM.BOX_WITH_MARGIN ? (placementReflect ? 'row-reverse' : 'row') : 'column'

  // TODO - this needs to be moved over to boxAndProducts module
  if ([CM.BOX_AND_2_PRODUCTS, CM.BOX_AND_4_PRODUCTS].includes(viewtype ?? '') && teaser) {
    const products = getItemsWithProductData(placement)
    const expectedProductCount = getExpectedProductCount(viewtype)
    if (products.length < expectedProductCount) return null
  }

  return (
    <>
      <div
        id={generateAnchorID(placementName)}
        className={clsx(
          styles.container,
          backgroundColor,
          {
            [styles.bannerContainer]: isBanner(viewtype),
            [styles.withBackgroundColor]:
              isTeaserBackgroundColor(backgroundColor) &&
              ![CM.BOX_WITH_MARGIN, CM.BOX_WITHOUT_MARGIN].includes(viewtype ?? ''),
            [styles.marginLateral]: marginLateral,
            [styles[`marginVertical${marginVertical}`]]: marginVertical,
            [styles.marginVerticalMobileM]: marginVertical === 'M',
            [styles[`flexDirection--${flexDirection}`]]: !!flexDirection,
          },
          styles[`container--${viewtype}`],
          `placement__${viewtype}`
        )}
        data-lxcm-lv={viewtype}
        data-cm-metadata={`[
          { _: 'properties.placement-${placementName}' },
          { placementRequest: [{ isInLayout: true, hasItems: true, placementName: '${placementName}' }] },
        ]`}
      >
        {hasCollectionText && (
          <CollectionText
            backgroundColor={backgroundColor}
            marginLateral={marginLateral}
            teaser={teaser as ICMCollection}
          />
        )}

        {!!modules &&
          modules?.map(node => {
            const Module = node.module as React.ElementType
            return Module ? (
              <Module key={`placement-${uuid()}`} placement={placement} teaserIndex={teaserIndex} viewType={viewtype} />
            ) : null
          })}
      </div>

      {hasCollectionCta && (
        <CollectionCta
          backgroundColor={backgroundColor}
          marginLateral={marginLateral}
          marginVertical={marginVertical}
          teaser={teaser as ICMCollection}
        />
      )}
    </>
  )
}
